import {
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
  NextPage,
} from "next";
import Head from "next/head";
import { Fragment } from "react";
import Template from "../components/templates/top.template";
import { usePage } from "@/hooks/ui/use-pages.hook";
import { useGetNotifications } from "@/hooks/api/get_notifications.hook";
import { useMount } from "@/hooks/ui/use-mount.hook";

export const getServerSideProps = async ({
  query,
  params,
}: GetServerSidePropsContext) => {
  return {
    props: {},
  };
};

type Props = InferGetServerSidePropsType<typeof getServerSideProps>;

const Page: NextPage<Props> = () => {
  const page = usePage();
  const getNotification = useGetNotifications();

  useMount(() => {
    getNotification.execute();
  });

  return (
    <Fragment>
      <Head>
        <title>Aun Kitchen</title>
        <link rel="preconnect" href="https://maps.googleapis.com" />
        <link rel="dns-prefetch" href="https://maps.googleapis.com" />
        <meta name="description" content={page.top.description} />
        <meta name="keywords" content={page.top.keywords.join(",")} />
      </Head>

      <Template
        fetching={getNotification.fetching}
        notifications={getNotification.result}
      />
    </Fragment>
  );
};

export default Page;
