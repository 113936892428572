import { usePage } from "@/hooks/ui/use-pages.hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Icon, List, styled, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { memo } from "react";
import { NotificationItem } from "../item/notification-item";

interface Props {
  list?: {
    id: string;
    date: Date;
    summary: string;
  }[];
}
export const SimplifiedNotifications: React.FC<Props> = memo((props) => {
  const router = useRouter();
  const page = usePage();

  return (
    <Wrapper>
      <StyledIcon>
        <Typography>お知らせ</Typography>
      </StyledIcon>

      <ListWrapper>
        <StyledList>
          {props.list?.map((item, index) => (
            <NotificationItem
              key={index}
              date={item.date}
              text={item.summary}
              onClick={() =>
                router.push(`${page.notifications.pathname}#${item.id}`)
              }
            />
          ))}
        </StyledList>

        <StyledButton onClick={() => router.push(page.notifications.pathname)}>
          お知らせ一覧
          <FontAwesomeIcon icon={["fas", "arrow-right"]} />
        </StyledButton>
      </ListWrapper>
    </Wrapper>
  );
});

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: theme.spacing(1),
  },
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  height: "auto",
  width: 135,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#68696C",
  color: "white",
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "unset",
    padding: theme.spacing(0.5, 0),
  },
}));

const ListWrapper = styled("div")(() => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
}));

const StyledList = styled(List)(() => ({
  width: "100%",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  display: "inline-flex",
  alignItems: "center",
  gap: theme.spacing(1),
  textDecoration: "underline",
  textUnderlineOffset: "4px",
  whiteSpace: "nowrap",
}));
