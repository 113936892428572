import { api } from "@/api/_module";
import { useCallback } from "react";

export const usePostMessageService = () => {
  const postMessageService = useCallback(
    async (command: {
      name: string;
      email: string;
      message: string;
    }): Promise<{
      ok: boolean;
      error?: unknown;
    }> => {
      try {
        const response = await api.postMessage.handle({
          body: {
            email: command.email,
            message: command.message,
            name: command.name,
          },
        });

        return { ok: response.body.ok };
      } catch (error) {
        return { ok: false, error };
      }
    },
    []
  );

  return { postMessageService };
};
