import { Api, Request, Response } from "@/architecture/api.architecture";

export type DeleteNotificationRequest = Request<
  {
    id: string;
  },
  {},
  {}
>;

type Res = Response<{
  ok: boolean;
}>;

export class DeleteNotificationApi extends Api<DeleteNotificationRequest, Res> {
  constructor() {
    super({
      method: "delete",
      pathname: "/api/notifications/:id",
    });
  }
}
