import { Fade, styled } from "@mui/material";
import Image from "next/image";
import { memo } from "react";

interface Props {
  src: string;
}
export const FadeLogo: React.FC<Props> = memo((props) => {
  return (
    <Fade in timeout={2500}>
      <StyledImage
        src={props.src}
        alt="logo"
        width={1000}
        height={1000}
        priority
      />
    </Fade>
  );
});

const StyledImage = styled(Image)(({ theme }) => ({
  width: "300px",
  height: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "200px",
    height: "auto",
  },
}));
