import { Fragment, memo } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

interface Props {
  width?: number;
  height?: number;
  latitude: number;
  longitude: number;
  apiKey: string;
}
export const Map: React.FC<Props> = memo((props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: props.apiKey,
  });

  return (
    <Fragment>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{
            width: '100%',
            height: '100%',
          }}
          center={{
            lat: props.latitude,
            lng: props.longitude,
          }}
          zoom={18}
        >
          <Marker position={{ lat: props.latitude, lng: props.longitude }} />
        </GoogleMap>
      )}
    </Fragment>
  );
});
