import { styled, Typography } from "@mui/material";
import Image from "next/image";
import { memo } from "react";

interface Props {
  imageSrc: string;
  title: string;
  description: string;
  subDescription: string;
}
export const TopContent: React.FC<Props> = memo((props) => {
  return (
    <Wrapper>
      <Title>{props.title}</Title>
      <Description>{props.description}</Description>

      <ImageWrapper>
        <StyledImage
          src={props.imageSrc}
          alt="top-content"
          width={1000}
          height={1000}
          priority
        />
      </ImageWrapper>

      <SubDescription>{props.subDescription}</SubDescription>
    </Wrapper>
  );
});

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bolder",
  fontSize: theme.fontSize.xxl,
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.fontSize.lg,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  fontSize: theme.fontSize.md,
  marginBottom: theme.spacing(4),
  whiteSpace: "normal",
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.fontSize.basic,
  },
}));

const SubDescription = styled(Typography)(({ theme }) => ({
  fontSize: theme.fontSize.md,
  marginTop: theme.spacing(4),
  whiteSpace: "normal",
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.fontSize.basic,
  },
}));

const ImageWrapper = styled("div")(({ theme }) => ({
  lineHeight: 0,
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  maxHeight: "50vh",
  display: "inline-flex",
}));

const StyledImage = styled(Image)(({ theme }) => ({
  width: "100%",
  height: "auto",
  objectFit: "cover",
}));
