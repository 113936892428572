import { Api, Request, Response } from "@/architecture/api.architecture";

export type CreateNotificationRequest = Request<
  {},
  {
    date: Date;
    summary: string;
    description: string;
  },
  {}
>;

type Res = Response<{
  ok: boolean;
}>;

export class CreateNotificationApi extends Api<CreateNotificationRequest, Res> {
  constructor() {
    super({
      method: "post",
      pathname: "/api/notifications",
    });
  }
}
