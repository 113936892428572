import { Api, Request, Response } from "@/architecture/api.architecture";

export type AuthHealthCheckRequest = Request<
  {},
  { user_id: string; password: string },
  {}
>;

type Res = Response<{
  ok: boolean;
  token: string;
}>;

export class AuthHealthCheckApi extends Api<AuthHealthCheckRequest, Res> {
  constructor() {
    super({
      method: "get",
      pathname: "/api/auth",
    });
  }
}
