import { DateUtil } from "@/utils/date-util";
import { ListItem } from "@mui/material";
import { ListItemButton, ListItemText } from "@mui/material";
import { styled } from "@mui/material";
import { memo } from "react";

interface Props {
  date: Date;
  text: string;
  onClick?: () => void;
}
export const NotificationItem: React.FC<Props> = memo((props) => {
  return (
    <ListItem>
      <StyledListItemButton onClick={props.onClick}>
        <ItemDate primary={DateUtil.format(props.date)} />
        <ItemText primary={props.text} />
      </StyledListItemButton>
    </ListItem>
  );
});

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderBottom: `solid 1px white`,
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(3),
  padding: theme.spacing(1, 0),
}));

const ItemDate = styled(ListItemText)(({ theme }) => ({
  flexGrow: 0,
  color: theme.color.grey[300],
}));

const ItemText = styled(ListItemText)(({ theme }) => ({
  flexGrow: 1,
}));
