import { Api, Request, Response } from "@/architecture/api.architecture";
import { NotificationModel } from "@/models/notification.model";

export type GetNotificationsRequest = Request<{}, {}, {}>;

type Res = Response<{
  ok: boolean;
  notifications: {
    id: string;
    date: string;
    summary: string;
    description: string;
  }[];
}>;

export class GetNotificationsApi extends Api<GetNotificationsRequest, Res> {
  constructor() {
    super({
      method: "get",
      pathname: "/api/notifications",
    });
  }
}
