import { EmailInput } from "@/components/atoms/email-input";
import { MessageInput } from "@/components/atoms/message-input";
import { NameInput } from "@/components/atoms/name-input";
import { Button, styled, useTheme } from "@mui/material";
import { memo, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";

interface Data {
  name: string;
  email: string;
  message: string;
}

interface Props {
  onSubmit: (form: Data) => void;
}
export const ContactForm: React.FC<Props> = memo((props) => {
  const theme = useTheme();
  const { handleSubmit, control } = useForm<Data>({
    defaultValues: { email: "", message: "", name: "" },
  });

  const submit = useCallback(
    (data: Data) => {
      props.onSubmit({
        email: data.email,
        message: data.message,
        name: data.name,
      });
    },
    [props]
  );

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <InputsWrapper>
        <Controller
          name="name"
          control={control}
          rules={{
            required: "名前を入力してください。",
            minLength: { value: 1, message: "1文字以上で入力してください。" },
          }}
          render={(props) => (
            <NameInput
              id="name"
              size="small"
              value={props.field.value}
              onChange={props.field.onChange}
              error={props.fieldState.invalid}
              helperText={props.fieldState.error?.message}
              errorTextColor={theme.color.yellow[500]}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{
            required: "メールアドレスを入力してください。",
          }}
          render={(props) => (
            <EmailInput
              id="email"
              size="small"
              value={props.field.value}
              onChange={props.field.onChange}
              error={props.fieldState.invalid}
              helperText={props.fieldState.error?.message}
              errorTextColor={theme.color.yellow[500]}
            />
          )}
        />
        <Controller
          name="message"
          control={control}
          rules={{
            required: "メッセージを入力してください。",
          }}
          render={(props) => (
            <MessageInput
              id="message"
              size="small"
              rows={6}
              value={props.field.value}
              onChange={props.field.onChange}
              error={props.fieldState.invalid}
              helperText={props.fieldState.error?.message}
              errorTextColor={theme.color.yellow[500]}
            />
          )}
        />
      </InputsWrapper>

      <Actions>
        <StyledButton type="submit" variant="contained">
          送信
        </StyledButton>
      </Actions>
    </Form>
  );
});

const Form = styled("form")(({ theme }) => ({
  color: "white",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

const InputsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const Actions = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: `white !important`,
  color: "black !important",
  minWidth: 100,
}));
