import { styled } from "@mui/material";
import React, { memo } from "react";
import { ContactForm } from "../form/contact-form";
import { Map } from "@/components/atoms/map";

interface Props {
  onSubmit: (form: { name: string; email: string; message: string }) => void;
}
export const TopContact: React.FC<Props> = memo((props) => {
  return (
    <Wrapper>
      <FormWrapper>
        <ContactForm onSubmit={props.onSubmit} />
      </FormWrapper>

      <MapWrapper>
        <Map
          latitude={35.601295}
          longitude={139.699294}
          apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}
        />
      </MapWrapper>
    </Wrapper>
  );
});

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  gap: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const FormWrapper = styled("div")(({ theme }) => ({
  flexGrow: 1,
}));

const MapWrapper = styled("div")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  lineHeight: 0,
  overflow: "hidden",
  minWidth: "45%",
  flexShrink: 0,
  margin: theme.spacing(2, 0),
  [theme.breakpoints.down("md")]: {
    height: 300,
    minWidth: "100%",
  },
}));
