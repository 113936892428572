import {
  addYears,
  differenceInMonths,
  differenceInYears,
  format,
  subMonths,
  isValid,
  addMinutes,
} from "date-fns";
import { ja } from "date-fns/locale";

export class DateUtil {
  /**
   * 引数のバリデーション
   */
  private static _isValid(date: Date | null): date is Date {
    if (!date || !isValid(new Date(date))) return false;
    return true;
  }

  /**
   * 引数に受けた日付データの月初を取得する
   */
  static getFirstDay(date: Date | null): Date | null {
    if (!this._isValid(date)) return null;

    const value = new Date(date);
    value.setDate(1);
    value.setHours(0);
    value.setMinutes(0);
    value.setSeconds(0);

    return value;
  }

  /**
   * 引数に受けた日付データの月末を取得する
   */
  static getLastDay(date: Date | null): Date | null {
    if (!this._isValid(date)) return null;

    const value = new Date(date);

    const lastDay = new Date(value.getFullYear(), value.getMonth() + 1, 0);

    return lastDay;
  }

  /**
   * 日付のフォーマット
   *
   * @example
   * DateUtil.format(new Date(), "yyyy/MM/dd");
   * // returns "2022/09/08"
   * @param {Date | null} date - 対象の日付
   * @param {string} form - 指定するフォーマット
   * @return {string} - フォーマット後の日付
   */
  static format(
    date: Date | null,
    form: string | undefined = "yyyy/MM/dd"
  ): string {
    if (!this._isValid(date)) return "";

    const value = new Date(date);
    return format(value, form, { locale: ja });
  }

  /** 年齢の計算 @ref:https://note.affi-sapo-sv.com/js-age-calculation_.php */
  static ageCalculation(birthDate: Date): number {
    if (!this._isValid(birthDate)) return 0;

    const nowDate = new Date();
    const age = nowDate.getFullYear() - birthDate.getFullYear();
    const thisYearsBirthday = new Date(
      nowDate.getFullYear(),
      birthDate.getMonth(),
      birthDate.getDate()
    );
    return age + (thisYearsBirthday.getTime() > nowDate.getTime() ? -1 : 0);
  }

  /**
   * 期間のラベル
   * @param startedAt
   * @param endedAt
   * @returns
   */
  static termLabel(startedAt: Date | null, endedAt: Date | null): string {
    const formatKey = "yyyy年M月";
    if (!this._isValid(startedAt)) return "不適切な期間";

    if (!endedAt) {
      return `${this.format(startedAt, formatKey)} ~ (未定)`;
    } else {
      //  @ref https://qiita.com/sota_yamaguchi/items/305374535aeb0ffd450a#differenceinyears-differenceinmonths%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95
      let date = startedAt;
      const end = subMonths(endedAt, -1);
      const diffYears = differenceInYears(end, date);
      if (diffYears) {
        date = addYears(date, diffYears);
      }
      const diffMonths = differenceInMonths(end, date);

      const year = diffYears ? `${diffYears}年` : "";
      const month = diffMonths ? `${diffMonths}ヶ月` : "";
      const termLabel = `${year}${month}`;
      return `${this.format(startedAt, formatKey)} ~ ${this.format(
        endedAt,
        formatKey
      )} (${termLabel})`;
    }
  }

  static addMinutes(date: Date, minutes: number): Date {
    return addMinutes(date, minutes);
  }

  static getStartDate(date: Date): Date {
    const startDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0
    );

    return startDate;
  }

  static getEndDate(date: Date): Date {
    const endDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + 1,
      0,
      0,
      0
    );

    return endDate;
  }
}
