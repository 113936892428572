import { Api, Request, Response } from "@/architecture/api.architecture";

export type SignInRequest = Request<
  {},
  { user_id: string; password: string },
  {}
>;

type Res = Response<{
  ok: boolean;
  token: string;
}>;

export class SignInApi extends Api<SignInRequest, Res> {
  constructor() {
    super({
      method: "post",
      pathname: "/api/sign_in",
    });
  }
}
