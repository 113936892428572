import { Api, Request, Response } from "@/architecture/api.architecture";

export type UpdateNotificationRequest = Request<
  {
    id: string;
  },
  {
    date: Date;
    summary: string;
    description: string;
  },
  {}
>;

type Res = Response<{
  ok: boolean;
}>;

export class UpdateNotificationApi extends Api<UpdateNotificationRequest, Res> {
  constructor() {
    super({
      method: "patch",
      pathname: "/api/notifications/:id",
    });
  }
}
