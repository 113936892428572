import { EffectCallback, useEffect, useRef } from "react";

export const useMount = (callback: EffectCallback) => {
  const ref = useRef(false);

  useEffect(() => {
    if (ref.current === false) {
      ref.current = true;

      callback();
    }
  }, [callback]);
};
