import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  styled,
} from "@mui/material";
import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

interface EmailInputProps {
  id?: string;
  label?: string;
  type?: string;
  name?: string;
  value?: string;
  error?: boolean;
  helperText?: string;
  size?: "small" | "medium";
  errorTextColor?: string;
  onChange?: (value: string) => void;
}
export const EmailInput: React.FC<EmailInputProps> = memo((props) => {
  const label = useMemo(() => props.label || "Email", [props.label]);
  const type = useMemo(() => props.type || "email", [props.type]);
  const name = useMemo(() => props.name || "email", [props.name]);

  const [value, setValue] = useState(props.value || "");
  const [error, setError] = useState(props.error || false);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setError(false);
      setValue(e.target.value);
      props.onChange?.(e.target.value);
    },
    [props]
  );

  useEffect(() => {
    setError(Boolean(props.error));
  }, [props.error]);

  return (
    <StyledFormControl
      fullWidth
      variant="outlined"
      error={error}
      size={props.size}
      params={{ errorTextColor: props.errorTextColor }}
    >
      <StyledFormLabel htmlFor={props.id}>{label}</StyledFormLabel>
      <OutlinedInput
        id={props.id}
        value={value}
        type={type}
        name={name}
        onChange={handleChange}
      />
      {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
    </StyledFormControl>
  );
});

const StyledFormControl = styled(FormControl)<{
  params: { errorTextColor?: string };
}>(({ theme, params }) => ({
  ".MuiInputBase-root": {
    backgroundColor: theme.palette.common.white,
  },
  ".MuiFormHelperText-root": {
    color: params.errorTextColor || theme.color.red,
  },
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: "inherit !important",
  opacity: 0.6,
}));
