import {
  styled,
  Container as MuiContainer,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import { memo, useCallback, useState } from "react";
import { FadeLogo } from "../organisms/brand/fade-logo";
import { SimplifiedNotifications } from "../organisms/content/simplified-notifications";
import { TopAboutUs } from "../organisms/content/top-about-us";
import { TopContact } from "../organisms/content/top-contact";
import { TopContent } from "../organisms/content/top-content";
import { TopMenu } from "../organisms/content/top-menu";
import { MainFooter } from "../organisms/footer/main-footer";
import { MainHeader } from "../organisms/header/main-header";
import { MainLayout } from "../_layouts/main-layout";
import { LoadingModal } from "../molecules/modal/loading-modal";
import { useSnackBar } from "@/hooks/ui/use-snackbar.hook";
import { usePostMessageService } from "@/hooks/api/post_message.hook";
import { notifications } from "@/assets/constants/notifications";
import { NotificationModel } from "@/models/notification.model";

interface Props {
  fetching?: boolean;
  notifications?: NotificationModel[];
}
const Template: React.FC<Props> = memo((props) => {
  const { postMessageService } = usePostMessageService();
  const [submitting, setSubmitting] = useState(false);
  const snackbar = useSnackBar();

  const handleMessageSubmit = useCallback(
    async (form: { name: string; email: string; message: string }) => {
      setSubmitting(true);

      const result = await postMessageService({
        email: form.email,
        message: form.message,
        name: form.name,
      });

      setSubmitting(false);

      if (result.ok) {
        snackbar.success("メッセージを送信しました。");
      } else {
        snackbar.alert("メッセージの送信に失敗しました。");
      }
    },
    [postMessageService, snackbar]
  );

  return (
    <MainLayout
      Header={<MainHeader />}
      Footer={<MainFooter />}
      backgroundFixedImageSrc={`${process.env.NEXT_PUBLIC_AWS_S3_BUCKET_URL}/images/top/background.jpg`}
    >
      <LoadingModal open={submitting} />

      <FadeLogoContainer>
        <FadeLogo
          src={`${process.env.NEXT_PUBLIC_AWS_S3_BUCKET_URL}/images/_brand/logo.png`}
        />
      </FadeLogoContainer>

      <Sections>
        <NotificationsSection>
          <MuiContainer>
            {props.fetching ? (
              <LoadingWrapper>
                <CircularProgress sx={{ color: "white" }} />
              </LoadingWrapper>
            ) : (
              <SimplifiedNotifications
                list={props.notifications?.map((notification) => ({
                  id: notification.id,
                  date: notification.date,
                  summary: notification.summary,
                }))}
              />
            )}
          </MuiContainer>
        </NotificationsSection>

        <TopContentSection>
          <MuiContainer>
            <TopContent
              imageSrc={`${process.env.NEXT_PUBLIC_AWS_S3_BUCKET_URL}/images/top/top-content.jpg`}
              title={`国産牛すじとごはんAun Kitchen`}
              description={`東京・長原にある小さなお店
              『おいしく健康』をコンセプトに心を込めて一つひとつ手作りしております。`}
              subDescription={`Aun Kitchenでは、国産牛すじメニューや日替わりメニューも
              野菜をたくさん使い、時間をかけ丁寧に作っております。`}
            />
          </MuiContainer>
        </TopContentSection>

        <TopMenuSection>
          <MuiContainer>
            <TopMenu
              src={`${process.env.NEXT_PUBLIC_AWS_S3_BUCKET_URL}/images/top/menu.jpg`}
              title="メニュー"
              description={`『美味しく健康に』
              30品目の食材で栄養バランスを考えたごはん。
              メイン、小鉢すべて日替わりで毎日食べても飽きないお昼ご飯をお楽しみいただけます。
              ご飯の上にある自家製しょうゆ糀で免疫力もアップ。
              その他牛すじカレーなど、どの世代でも楽しめるメニューになっております。`}
            />
          </MuiContainer>
        </TopMenuSection>

        <TopAboutUsSection id="access">
          <MuiContainer>
            <TopAboutUs
              src={`${process.env.NEXT_PUBLIC_AWS_S3_BUCKET_URL}/images/top/access.jpg`}
              address="東京都大田区上池台1-45-15"
              tel="03-6425-6588"
              // businessHours={`ランチタイム 11:30 ~ 14:00 *完売次第終了
              // ディナー 17:30 ~ 20:00
              // 土曜 11:30 ~ 13:00  *完売次第終了
              // `}
              regularHoliday="日曜日 祝日"
              access="池上線長原駅から徒歩3分, 大井町線旗の台から徒歩7分"
            />
          </MuiContainer>
        </TopAboutUsSection>

        <TopContactSection id="contact">
          <MuiContainer>
            <SectionTitle>お問い合せ</SectionTitle>
            <TopContact onSubmit={handleMessageSubmit} />
          </MuiContainer>
        </TopContactSection>
      </Sections>
    </MainLayout>
  );
});

export default Template;

const FadeLogoContainer = styled(MuiContainer)(({ theme }) => {
  const height = window.innerHeight;

  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: `calc(${height}px - 94px)`,
    [theme.breakpoints.down("sm")]: {
      height: `calc(${height}px - 64px)`,
    },
  };
});

const Sections = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(12),
  backgroundColor: theme.palette.primary.main,
  color: "white",
  padding: theme.spacing(12, 0),
}));

const NotificationsSection = styled("section")(({ theme }) => ({}));

const TopContentSection = styled("section")(({ theme }) => ({}));

const TopMenuSection = styled("section")(({ theme }) => ({}));

const TopAboutUsSection = styled("section")(({ theme }) => ({
  paddingTop: 94,
  marginTop: -94,
  [theme.breakpoints.down("sm")]: {
    paddingTop: 64,
    marginTop: -64,
  },
}));

const TopContactSection = styled("section")(({ theme }) => ({
  paddingTop: 94,
  marginTop: -94,
  [theme.breakpoints.down("sm")]: {
    paddingTop: 64,
    marginTop: -64,
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: "bolder",
  fontSize: theme.fontSize.lg,
}));

const LoadingWrapper = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
}));
