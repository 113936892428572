import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export namespace ApiOption {
  export type Method = "get" | "post" | "delete" | "patch" | "put";
  export type ReqQuery = {
    [key: string]: string | number | string[] | boolean | number[];
  };
  export type ReqParams = {
    [key: string]: string | number;
  };
}

export type Request<Params, Body, Query> = {
  params?: Params;
  body?: Body;
  query?: Query;
  accessToken?: string | null;
  responseType?: "blob";
};

export type Response<Body> = {
  body: Body;
};

export class Api<
  Req extends Request<ApiOption.ReqParams, unknown, ApiOption.ReqQuery>,
  Res extends Response<unknown>
> {
  readonly pathname: string;
  private readonly method: ApiOption.Method;
  private readonly baseUrl?: string;

  constructor(option: {
    pathname: string;
    method: ApiOption.Method;
    baseUrl?: string;
  }) {
    this.method = option.method;
    this.pathname = option.pathname;
    this.baseUrl = option.baseUrl;
  }

  async handle(req: Req): Promise<Res> {
    await this.sleep(1000);

    const res = await this.axios(req);
    return { body: res.data } as Res;
  }

  private async axios(req: Req): Promise<AxiosResponse<Res>> {
    const url = this.getUrl(req.query, req.params);

    let config: AxiosRequestConfig = {};

    if (req.accessToken) {
      config = {
        headers: { Authorization: `Bearer ${req.accessToken}` },
      };
    }

    if (this.method === "get" || this.method === "delete") {
      const res = await axios[this.method]<Res>(url, config);
      return res;
    } else if (
      this.method === "post" ||
      this.method === "patch" ||
      this.method === "put"
    ) {
      const res = await axios[this.method]<Res>(url, req.body, config);
      return res;
    } else {
      throw new Error();
    }
  }

  private getUrl(
    query?: ApiOption.ReqQuery,
    params?: ApiOption.ReqParams
  ): string {
    let url = `${this.pathname}`;

    //  replace query
    if (query) {
      let queryString = "";

      const keys = Object.keys(query);
      keys.forEach((key) => {
        const value = query[key];
        if (value === undefined) return; // valueがundefinedなら、キー自体存在させない

        if (Array.isArray(value)) {
          const formattedQuery = `${key}=${value.join(",")}`;
          queryString = queryString
            ? `${queryString}&${formattedQuery}`
            : `?${formattedQuery}`;
        } else {
          const formattedQuery = `${key}=${query[key]}`;
          queryString = queryString
            ? `${queryString}&${formattedQuery}`
            : `?${formattedQuery}`;
        }
      });

      url += queryString;
    }

    //  replace params
    if (params) {
      const keys = Object.keys(params);
      keys.forEach((key) => {
        url = url.replace(`/:${key}`, `/${params[key]}`);
      });

      if (url.includes("/:")) throw new Error();
    }

    return url;
  }

  private sleep(milliseconds: number): Promise<unknown> {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }
}
