import { usePage } from "@/hooks/ui/use-pages.hook";
import { Button, styled, Typography } from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";
import { memo } from "react";

interface Props {
  src: string;
  title: string;
  description: string;
}
export const TopMenu: React.FC<Props> = memo((props) => {
  const router = useRouter();
  const page = usePage();

  return (
    <Wrapper>
      <ImageWrapper>
        <StyledImage
          src={props.src}
          alt="top-menu"
          width={1000}
          height={1000}
          priority
          style={{ width: "100%", height: "auto" }}
        />
      </ImageWrapper>

      <Detail>
        <Title>{props.title}</Title>

        <Description>{props.description} </Description>

        <StyledButton
          variant="outlined"
          onClick={() => router.push(page.menu.pathname)}
        >
          一覧はこちら
        </StyledButton>
      </Detail>
    </Wrapper>
  );
});

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(4),
  alignItems: "center",
  padding: theme.spacing(6),
  border: `solid 1px ${theme.palette.common.white}`,
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(3, 3, 6),
  },
}));

const ImageWrapper = styled("div")(({ theme }) => ({
  width: "50%",
  flexShrink: 0,
  lineHeight: 0,
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const Detail = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(4),
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.fontSize.xl,
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.fontSize.lg,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  fontSize: theme.fontSize.basic,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderColor: "white",
  minWidth: 200,
}));

const StyledImage = styled(Image)(({ theme }) => ({
  maxHeight: "40vh",
  objectFit: "cover",
}));
