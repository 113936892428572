import { api } from "@/api/_module";
import { notifications } from "@/assets/constants/notifications";
import { NotificationModel } from "@/models/notification.model";
import { useCallback, useState } from "react";
import { useSnackBar } from "../ui/use-snackbar.hook";

export const useGetNotifications = () => {
  const [fetching, setFetching] = useState(true);
  const [result, setResult] = useState<NotificationModel[]>();
  const snackbar = useSnackBar();

  const execute = useCallback(async () => {
    setFetching(true);
    const response = await api.getNotifications.handle({}).catch((err) => {
      setFetching(false);
      const message = "データの取得に失敗しました。";
      snackbar.alert(message);
      return { body: { notifications: [] } };
    });
    setResult(
      response.body.notifications.map((notification) => ({
        date: new Date(notification.date),
        description: notification.description,
        id: notification.id,
        summary: notification.summary,
      }))
    );
    setFetching(false);
    return response;
  }, [snackbar]);

  return { execute, fetching, result };
};
