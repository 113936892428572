import { PostMessageApi } from "./post_message.api";
import { CreateNotificationApi } from "./create_notification.api";
import { GetNotificationsApi } from "./get_notifications.api";
import { UpdateNotificationApi } from "./update_notification.api";
import { DeleteNotificationApi } from "./delete_notification.api";
import { SignInApi } from "./sign_in.api";
import { AuthHealthCheckApi } from "./auth_health_check.api";

class ApiModule {
  readonly postMessage: PostMessageApi;
  readonly createNotification: CreateNotificationApi;
  readonly getNotifications: GetNotificationsApi;
  readonly updateNotification: UpdateNotificationApi;
  readonly deleteNotification: DeleteNotificationApi;
  readonly signIn: SignInApi;
  readonly authHealthCheck: AuthHealthCheckApi;

  constructor() {
    this.postMessage = new PostMessageApi();
    this.createNotification = new CreateNotificationApi();
    this.getNotifications = new GetNotificationsApi();
    this.updateNotification = new UpdateNotificationApi();
    this.deleteNotification = new DeleteNotificationApi();
    this.signIn = new SignInApi();
    this.authHealthCheck = new AuthHealthCheckApi();
  }
}

const api = new ApiModule();

export { api };
