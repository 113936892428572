import { useCallback, useContext } from "react";
import { SnackbarContext } from "@/providers/snackbar-context.provider";

export const useSnackBar = () => {
  const { setAlert, setInfo, setSuccess, setWarning } =
    useContext(SnackbarContext);

  const success = useCallback(
    (message: string) => setSuccess(message),
    [setSuccess]
  );

  const info = useCallback((message: string) => setInfo(message), [setInfo]);

  const warning = useCallback(
    (message: string) => setWarning(message),
    [setWarning]
  );

  const alert = useCallback((message: string) => setAlert(message), [setAlert]);

  return { success, info, warning, alert };
};
