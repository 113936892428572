import { CircularProgress, Modal, styled, Box } from "@mui/material";
import { memo } from "react";

interface LoadingModalProps {
  open?: boolean;
}
export const LoadingModal: React.FC<LoadingModalProps> = memo((props) => {
  return (
    <StyledModal open={props.open || false}>
      <Container>
        <StyledCircularProgress size={50} />
      </Container>
    </StyledModal>
  );
});

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const StyledModal = styled(Modal)(({ theme }) => ({
  zIndex: 10000,
}));
