import { styled, Typography } from "@mui/material";
import Image from "next/image";
import { memo } from "react";

interface Props {
  src: string;
  address?: string;
  tel?: string;
  businessHours?: string;
  regularHoliday?: string;
  access?: string;
  parking?: string;
  payment?: string;
}
export const TopAboutUs: React.FC<Props> = memo((props) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <StyledImage
          src={props.src}
          alt="top-about-us"
          width={1000}
          height={1000}
          priority
        />
      </ImageWrapper>

      <Detail>
        {props.address && (
          <Item>
            <Label>住所</Label>
            <Text>{props.address}</Text>
          </Item>
        )}

        {props.tel && (
          <Item>
            <Label>電話番号</Label>
            <Text>{props.tel}</Text>
          </Item>
        )}

        {props.businessHours && (
          <Item>
            <Label>営業時間</Label>
            <Text>{props.businessHours}</Text>
          </Item>
        )}

        {props.regularHoliday && (
          <Item>
            <Label>定休日</Label>
            <Text>{props.regularHoliday}</Text>
          </Item>
        )}

        {props.access && (
          <Item>
            <Label>アクセス</Label>
            <Text>{props.access}</Text>
          </Item>
        )}

        {props.parking && (
          <Item>
            <Label>駐車場</Label>
            <Text>{props.parking}</Text>
          </Item>
        )}

        {props.payment && (
          <Item>
            <Label>利用可能カード、電子マネー</Label>
            <Text>{props.payment}</Text>
          </Item>
        )}
      </Detail>
    </Wrapper>
  );
});

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(4),
  alignItems: "stretch",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ImageWrapper = styled("div")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  lineHeight: 0,
  width: "50%",
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const Detail = styled("div")(({ theme }) => ({
  padding: theme.spacing(2, 0),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
}));

const Item = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1.5),
}));

const Label = styled(Typography)(({ theme }) => ({
  borderLeft: `solid 4px ${theme.palette.common.white}`,
  paddingLeft: theme.spacing(2),
  fontSize: theme.fontSize.lg,
  lineHeight: 1,
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.fontSize.md,
  },
}));

const Text = styled(Typography)(({ theme }) => ({}));

const StyledImage = styled(Image)(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  maxHeight: "50vh",
  borderRadius: theme.shape.borderRadius,
}));
